import { ethers } from "ethers";
import { IdentitySDK } from "@onchain-id/identity-sdk";

async function getClaimId(address_issuer, topic) {
	const claimId = ethers.utils.keccak256(
	ethers.utils.defaultAbiCoder.encode(
		["address", "uint256"],
		[address_issuer, parseInt(topic)]
	)
	);
	if (claimId) {
		return claimId;
	} else {
		return;
	}
}

async function getClaim(identity, issuer, topic) {
	const address_issuer = issuer.address;
	const claimId = await getClaimId(address_issuer, topic);

	if (claimId) {
		//The claims are auto-issued, it can only be one claim with this topic
		let claim = await identity.getClaim(claimId);
		return claim;
	}
}

async function createClaim(data, identity, signer) {
	const data2hex = "0x" + Buffer.from(data).toString("hex");
	const claim = new IdentitySDK.Claim({
		address: identity.address,
		data: data2hex,
		issuer: identity.address,
		emissionDate: Date.now(),
		scheme: 1,
		topic: 4880,
		uri: "",
	});
	const customSigner = new IdentitySDK.SignerModule({
		publicKey: await signer.getAddress(),
		signMessage: signer.signMessage.bind(signer)
	});
	try {
		await claim.sign(customSigner);
	} catch(error) {
		console.log(error);
		throw new Error('Stopped by the user');
	}
	return claim;
}

export {
	getClaim,
	createClaim
};
