<template>
    <div class="">
        <Address v-if="!identity" />
        <ConnectionOptions v-else />
    </div>
</template>

<script>
import Address from '@/components/login/Address.vue';
import ConnectionOptions from '@/components/login/ConnectionOptions.vue';
import { mapState } from 'vuex';

export default {
    components: {
        Address, 
        ConnectionOptions
    },

    data() {
        return {
            address: null,
            error: null
        }
    },

    computed: {
        ...mapState('global', ['identity']),
    }
}
</script>
