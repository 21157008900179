import defaultConfig from '@/config/default.json';
import development from '@/config/development.json';
import develop from '@/config/develop.json';
// import prod from '@/config/prod.json';
import production from '@/config/production.json';
// import staging from '@/config/staging.json';

function setEnvironmentFile() {
    const environment = process.env.NODE_ENV;
    switch (environment) {
        case 'development':
            return development;
        case 'production':
            return production;
        case 'develop':
            return develop;
        default:
            return development;
    }
}

function setVariables() {
    let finalConfig = defaultConfig;
    let envFile = setEnvironmentFile();
    for (let [key, value] of Object.entries(envFile)) {
        finalConfig[key] = value;
    }
    finalConfig["GOOGLE_CLIENT_ID"] = process.env.VUE_APP_GOOGLE_CLIENT_ID;
    finalConfig["MICROSOFT_CONFIG"]["auth"]["clientId"] = process.env.VUE_APP_MICROSOFT_CLIENT_ID;
    finalConfig["MICROSOFT_CONFIG"]["auth"]["redirectUri"] = finalConfig["HOSTNAME"];
    finalConfig["GITHUB"]["clientId"] = process.env.VUE_APP_GITHUB_CLIENT_ID;
    finalConfig["GITHUB"]["redirectUri"] = finalConfig["HOSTNAME"] + "validation" 
    return finalConfig;
}

const config = setVariables();
export default config;
