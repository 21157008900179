<template>
    <div class="max-w-2xl mx-auto py-7 my-7 border-l-4 border-green-600 bg-green-100">
        <p class="font-bold text-xl text-green-800">The file is ready for download</p>
        <button class="mt-4 px-6 py-2 text-lg text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg to-green-300 from-green-400 hover:scale-110 transform" @click="downloadFile()" >Download <i class="fas fas fa-cloud-download-alt ml-1"></i></button>         
    </div> 
</template>

 
<script>

export default {
    props: {
        filename: String,
        checkbox: Object,
        downloadableFile: Object,
    },
    data() {
        return {

        }     
    },

    methods: {
        downloadFile() {
            var link = document.createElement('a');
            if(this.checkbox.encrypt) {
                link.download = 'encrypted_' + this.filename + '.sda';
            }
            else if(this.checkbox.sign) {
                link.download = 'encrypted_' + this.filename + '.asc';
            }
            else {
                link.download = 'decrypted_' + this.getFileName();
            }

            const blobFromEncrypted = new Blob([ this.downloadableFile ]);
            link.href = window.URL.createObjectURL(blobFromEncrypted);
            link.click();
            this.file = new File([""], "");
        },

        getFileName() {
            if(!this.filename.startsWith('encrypted_')) {
                return this.filename.substring(0, this.filename.length - 4);
            } else {
                return this.filename.substring(10, this.filename.length - 4);
            }
        },
    }
}
</script>
