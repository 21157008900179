<template>
        <section ref="drag" v-cloak @drop.prevent="droppedFile" @dragover.prevent class="relative h-44 rounded-lg shadow-inner transition-colors" :class="isOver ? 'bg-green-300' : 'bg-gray-50'">
            <div v-if="file.size == 0">
                <label class="absolute transform translate-x-1/2 right-1/2 top-1/2 -translate-y-1/2  cursor-pointer transition-colors" :class="isOver ? 'text-green-500' : 'text-gray-300 hover:text-gray-400'" for="selectedFiles">
                        <i class="fas fa-file-upload text-7xl mb-3"></i>
                        <p>Select or drag and drop one file</p>
                </label>
                <input class="hidden" id="selectedFiles" type="file" files="false" @change="browseFile">
            </div>
            <div v-else class="absolute transform -translate-y-1/2 top-1/2 translate-x-1/2 right-1/2 grid grid-cols-6 gap-4 w-full">
                <FileTypePreview :fileType="file.type" class="text-8xl col-span-2 lg:col-span-1"/>
                <div class="flex flex-col text-left text-gray-500 text-sm col-span-4 lg:col-span-3">
                    <p class="font-bold text-gray-600 text-base overflow-ellipsis overflow-hidden">{{file.name}}</p>
                    <p>{{localFile.type}}</p>
                    <p>{{localFile.size}}</p>
                    <p>{{shortDate}}</p>
                </div>
                <div class="flex items-center col-span-6 lg:col-span-2 mx-auto">
                    <button class="bg-gray-500 py-1 px-7 transition-colors rounded-md font-bold text-white hover:bg-gray-600" @click="reset()"><i class="fas fa-trash-alt text-white mr-2"></i>Remove</button>
                </div>
            </div>
        </section>
</template>

<script>
// import * as openpgp from 'openpgp';
import FileTypePreview from '@/components/FileTypePreview.vue';
export default {
    components: {
        FileTypePreview,
    },

    props: {
        file: File || Object,
    },

    data() {
        return {
            isOver: false,
            localFile: new File([""], ""),
        }
    },

    methods: {
        async droppedFile(elm) {
            this.isOver = false;
            const files = elm.dataTransfer.files;
            this.localFile = files[0];
            this.$emit('update:file', this.localFile);
        },

        async browseFile(elm) {
            const files = elm.target.files;
            if(files.length == 1) {
                this.localFile = files[0];
                this.$emit('update:file', this.localFile);
            }
        },

        toDoubleDigits(number) {
            if(number <= 9) return `0${number}`; 
            return number;
        },

        reset() {
            this.$emit('update:file', new File([""], ""));
        }
    },

    computed: {
        shortDate() {
            const date = this.localFile.lastModifiedDate;
            return `${this.toDoubleDigits(date.getDate())}/${this.toDoubleDigits(date.getMonth() + 1)}/${date.getFullYear()}`;
        }
    },

    mounted() {
        this.$refs.drag.addEventListener("dragover", () => {
			this.isOver = true; // add class on drag over
		});
		this.$refs.drag.addEventListener("dragleave", () => {
			this.isOver = false; // remove class on drag leave
		});
    }
}
</script>
