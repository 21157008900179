<template>
    <i :class="`fas fa-${cleaned}`"></i>
</template>

<script>

export default {
    props: {
        fileType: String
    },

    data() {
        return {

        }
    },

    computed: {
        cleaned() {
            if(!this.fileType) return 'file';
            const splitted = this.fileType.split('/');
            if(splitted.length > 1) {
                switch(splitted[0]) {
                    case 'audio':
                        return 'file-audio';
                    case 'video':
                        return 'file-video';
                    case 'application':
                        return 'file';
                    case 'font':
                        return 'fonticons';
                    case 'image':
                        return 'file-image';
                    case 'text':
                        return 'file-alt';
                    default:
                        return 'file'
                }
            }

            return 'file';
        }
    }
}
</script>
