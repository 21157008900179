<template>
    <div class="">
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-2xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <i class="absolute text-xl transition-transform transform cursor-pointer hover:scale-110 top-6 right-6 fas fa-times" @click="submit()"></i>
                <div class="flex flex-col mt-3 text-center sm:mt-0 sm:ml-4 ">
                    <h3 class="text-2xl font-medium text-gray-900">Passphrase</h3>
                    <div class="mt-5 mx-auto">
                        <p class="mb-5">
                            Enter the passphrase for your private PGP Key.
                        </p>
                        <input class="shadow appearance-none border rounded py-2 px-3 outline-none w-full mb-5 text-center" v-model="passphrase" type="password" placeholder="Your passphrase">
                        <button class="mt-2 px-6 py-2 text-lg text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg to-green-300 from-green-400 hover:scale-110 transform" @click="submit()" >Submit</button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            passphrase: null,
        }
    },

    methods: {
        submit() {
            this.$emit('close', this.passphrase);
        }
    }
}

</script>
