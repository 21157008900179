<template>
    <div class="">
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-2xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <i v-if="phase != 'success'" class="absolute text-xl transition-transform transform cursor-pointer hover:scale-110 top-6 right-6 fas fa-times" @click="close()"></i>
                <div class="flex flex-col mt-3 text-center sm:mt-0">
                    <h3 class="text-2xl font-medium text-gray-900">Import Private Key</h3>
                    <div v-if="phase == 'form'" class="mt-5 w-5/6 mx-auto">
                        <p class="mb-3 text-left">You can simply paste the encrypted private key here</p>
                        <div class="">
                            <h1 class="text-left text-xl mb-2">Encrypted Private Key</h1>
                            <textarea ref="text" class="w-full bg-gray-50 h-36 outline-none p-4 rounded-lg shadow-inner resize-none" v-model="privateKey" placeholder="Paste your encrypted private key here"></textarea>
                        </div>
                        <div class="text-center text-red-500 text-md font-medium my-2" :class="error != 'None' ? 'opacity-100' : 'opacity-0'">{{this.error}}</div>
                        <button class="mb-5 px-6 py-2 text-lg text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg from-green-400 to-green-300 hover:scale-110 transform" @click="submit()">Import</button> 
                    </div>
                    <Stepper v-else-if="phase == 'decrypt'" class="mt-5 w-5/6 mx-auto text-center" :stepper="{counter: 0, steps: ['Need your Metamask Private Key in order to decrypt the PGP private key']}" />
                    <div v-else-if="phase == 'question'" class="mt-7 w-5/6 mx-auto">
                        <p class="my-3 text-left">The public key stored on the blockchain isn't derivated from the submitted private key.<br/><b>Do you want to update your public key ?</b></p>
                        <div class="flex justify-around">
                            <button class="my-5 px-6 py-2 text-lg text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg from-green-400 to-green-300 hover:scale-110 transform" @click="updatePublicKey()">Yes</button> 
                            <button class="my-5 px-6 py-2 text-lg text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg from-red-400 to-red-300 hover:scale-110 transform" @click="close()">No</button> 
                        </div>
                    </div>
                    <Stepper v-else-if="phase == 'processing'" class="mt-5 w-5/6 mx-auto text-center" :stepper="stepper" />
                    <div v-else-if="phase == 'success'" class="mt-7 w-5/6 mx-auto">
                        <i class="fas fa-check-circle bg-gradient-to-br text-green-400" style="font-size: 7rem"></i>
                        <p class="my-3 text-center">Private Key has been imported successfully</p>
                        <button class="my-5 px-6 py-2 text-lg text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg from-green-400 to-green-300 hover:scale-110 transform" @click="close()">Close</button> 
                    </div>
                    <div v-else-if="phase == 'failed'" class="mt-7 w-5/6 mx-auto">
                        <i class="fas fa-times-circle bg-gradient-to-br text-red-400" style="font-size: 7rem"></i>
                        <p class="my-3 text-center">{{ error }}</p>
                        <button class="my-5 px-6 py-2 text-lg text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg from-red-400 to-red-300 hover:scale-110 transform" @click="close()">Close</button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import * as openpgp from 'openpgp';
import { getPGPPublicKey, savePrivateKeyToLocalStorage } from '@/modules/utils';
import { createClaim } from '@/modules/claims';
import Stepper from '@/components/Stepper.vue';
import config from '@/config';
export default {
    components: {
        Stepper,
    },
    data() {
        return {
            error: 'None',
            privateKey: null,
            phase: "form",
            stepper: {
                counter: 0,
                steps: [
                    'Need your Metamask Private Key in order to decrypt the PGP private key',
                    'Need you to sign the claim containing your public key',
                    'Need you to confirm the transaction to emit the claim on the blockchain',
                    'Waiting the transaction to complete...'
                ]
            },
            hyperlinkElements: ['Waiting the transaction to complete...<br/>You can see the transaction details <a href="', '" class="text-blue-500 hover:underline visited:text-pink-600" target="_blank">here</a>']
        }
    },

    computed: {
        ...mapState('global', ['accountAddress', 'identity', 'signer'])
    },

    methods: {

        async decryptPGPKey(encryptedMessage) {
            const decryptedMessage = await window.ethereum.request({
                method: 'eth_decrypt',
                params: [encryptedMessage, this.accountAddress],
            })
            return decryptedMessage;
        },

        async submit() {
            try {
                this.phase = 'decrypt'
                const decr = await this.decryptPGPKey(this.privateKey);
                let privateK = await openpgp.readKey({ armoredKey: decr });
                let publicK = privateK.toPublic();
                let armoredPublicKey = publicK.armor();
                const original = await getPGPPublicKey(this.identity);
                if(original === armoredPublicKey) {
                    savePrivateKeyToLocalStorage(this.identity.address, this.privateKey);
                    this.phase = "success";
                }
                else {
                    this.phase = "question"
                }
            } catch(error) {
                this.phase = 'form';
                this.error = error.message;
                setTimeout(() => {
                    this.error = 'None'
                }, 5000);
            }
        },

        async updatePublicKey() {
            this.stepper.counter = 0;
            this.phase = "processing";
            const decr = await this.decryptPGPKey(this.privateKey);
            this.stepper.counter++;
            let privateK = await openpgp.readKey({ armoredKey: decr });
            let publicK = privateK.toPublic();
            let armoredPublicKey = publicK.armor();
            await this.submitClaim(armoredPublicKey);
            this.phase = "success";
        },

        async submitClaim(data) {
            try {
                const claim = await createClaim(data, this.identity, this.signer);
                this.stepper.counter++;
                const tx = await this.identity.addClaim(claim.topic, claim.scheme, claim.issuer, claim.signature, claim.data, claim.uri, {signer: this.signer});
                if(config["network"]["blockExplorerUrls"] && config["network"]["blockExplorerUrls"].length > 0) {
                    this.stepper.steps[3] = this.hyperlinkElements[0] + config['network']["blockExplorerUrls"][0] + '/tx/' + tx.hash + this.hyperlinkElements[1]
                }
                this.stepper.counter++;
                await tx.wait();
                savePrivateKeyToLocalStorage(this.identity.address, this.privateKey);
            } catch(error) {
                this.phase = 'failed';
                this.error = error.message;
                console.error("Error while processing the transaction", error);
                throw new Error(error.message);
            }
        },

        close() {
            this.$emit('close');
        }
    }
}

</script>
