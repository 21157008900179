<template>
    <div :class="disabled && 'opacity-30'">
        <div class="flex flex-col sm:flex-row justify-center items-center" :class="{ 'text-green-600': state}" @click="change()">
            <h2>{{ name }}</h2>
            <div class="w-12 h-6 mx-4 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out cursor-pointer" :class="{ 'bg-green-400': state}">
                <div class="bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out" :class="{ 'translate-x-6': state}"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        state: Boolean,
        name: String,
        disabled: Boolean,
    },
    data() {
        return {
        }
    },
    methods: {
        
        change() {
            if(!this.disabled)
                this.$emit('update:state', !this.state);
        }
    },
    computed: {
       

    }
}
</script>