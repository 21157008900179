<template>
    <div>
        <button @click="login" type="button" class="flex w-full bg-white py-2 px-4 items-center border-solid border border-black">
            <div class="mr-2">
                <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                    <g fill="#000" fillRule="evenodd">
                    <path
                        d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                        fill="#EA4335"
                    ></path>
                    <path
                        d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                        fill="#4285F4"
                    ></path>
                    <path
                        d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                        fill="#FBBC05"
                    ></path>
                    <path
                        d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                        fill="#34A853"
                    ></path>
                    <path fill="none" d="M0 0h18v18H0z"></path>
                    </g>
                </svg>
            </div>
            <span class="">Login from Google</span>
        </button>
        </div>
</template>

<script>
import config from '@/config';
import { validateJWTGoogle } from '@/modules/validate-jwt';
import { getClaim } from '@/modules/claims';
import { hashStrInSha256 } from '@/modules/utils';
import { mapState } from 'vuex';
export default {
    props: {
        onSuccess: Function,
        onFailure: Function,
    },

    data() {
        return {
        }       
    },

    computed: {
        ...mapState('global', ['identity']),
    },

    methods: {

        loadScript(doc, balise, id, jsSrc, onSuccess, onError) {
            const element = doc.getElementsByTagName(balise)[0];
            const fjs = element;
            let js = element;
            js = doc.createElement(balise);
            js.id = id;
            js.src = jsSrc;
            if (fjs && fjs.parentNode) {
                fjs.parentNode.insertBefore(js, fjs);
            } else {
                doc.head.appendChild(js);
            }
            js.onerror = onError;
            js.onload = onSuccess;
        },

        callbackOnSuccess() {
            const params = {
                client_id: config.GOOGLE_CLIENT_ID,
                scope: "openid",
                };

                window.gapi.load("auth2", () => {
                const GoogleAuth = window.gapi.auth2.getAuthInstance();
                if (!GoogleAuth) {
                    window.gapi.auth2.init(params);
                }
            });
        },

        async login() {
            if (window.gapi && window.gapi.auth2) {
                const response = await window.gapi.auth2.getAuthInstance().signIn({
                    prompt: "select_account",
                    scope: "openid",
                })
                await this.handleResponse(response);
            } else {
                this.onFailure("Try to reload the page");
            }
        },

        async handleResponse(response) {
            if (response !== null) {
                const ID_TOKEN = response.mc.id_token;
                if (await validateJWTGoogle(ID_TOKEN, config.GOOGLE_CLIENT_ID)) {
                    const googleId = response.Aa;
                    const claim = await getClaim(this.identity, this.identity, config.TOPICS.GOOGLE);
                    const hashGoogleId = hashStrInSha256(googleId);
                    if (claim && claim.data === hashGoogleId) {
                        this.onSuccess();
                    } else {
                        this.onFailure("Error while connecting. Thanks to retry later."); 
                    }
                }
            } else {
                this.onFailure("Window closed by the user");
            }
        },
    },

    mounted() {
        this.loadScript(
            document,
            "script",
            "google-login",
            "https://apis.google.com/js/platform.js",
            this.callbackOnSuccess,
            (err) => {
                console.error(err);
            }
        )
    }
}
</script>
