<template>
    <section>
        <div class="text-4xl mt-16">
            Messages Secured Normally
        </div>
        <p class="my-12 text-lg">This Web Application is a part of the Identintech project.</p>
        <router-link to="/encrypt">
            <button class="px-12 py-3 text-3xl text-white font-bold bg-gradient-to-br rounded-lg shadow-lg to-green-300 from-green-400 transition-transform transform hover:scale-110 focus:outline-none">Get Started</button>
            
        </router-link>

    </section>
</template>

<script>
export default {
    
}
</script>
