<template>
    <div v-if="mounted">
        <div v-if="passphrase.modalState" class="fixed w-full z-20 top-0 bottom-0 left-0 overflow-y-auto">
            <div class="fixed w-full" aria-hidden="true">
                <div class="w-full h-screen bg-gray-500 opacity-75"></div>
            </div>
            <PassphraseModal @close="closePassphraseModal($event)"/>
        </div>
        <h1 class="text-4xl mb-7">Encrypt</h1>
        <Tabs :mode.sync="mode"/>
        <div class="shadow-xl rounded-b-lg py-7 px-12 mx-auto mb-12">
            <div class="mb-2">
                <h1 class="text-xl mb-2">Receiver Address</h1>
                <input type="text" class="w-2/3 bg-gray-50 p-2 text-center outline-none shadow-inner rounded-lg" placeholder="0x0000000000000000000000000000000000000000" v-model="receiverAddress.input">
            </div>
            <p class="text-red-500 cursor-default" :class="error=='None' ? 'opacity-0' : 'opacity-100'">{{ error }}</p>
            <div v-if="connected" class="grid grid-cols-2 w-2/3 mx-auto mb-5">
                <Checkbox :state.sync="checkbox.encrypt" name="Encrypt"/>
                <Checkbox :state.sync="checkbox.sign" name="Sign" :disabled="!privateKeyInLocalStorage"/>
            </div>
            <div v-else class="flex text-red-800 font-medium items-center justify-center">
                <i class="fas fa-user-secret mr-4 text-3xl"></i>
                <p class="text-left">You are currently in <b>anonymous mode</b>. You are only allowed to encrypt a {{ this.mode }}.<br />Please <a @click="redirect('login')" class="cursor-pointer underline font-bold">login</a> to access more functionnalities.</p>
            </div>
            <div v-if="connected && !privateKeyInLocalStorage" class="flex items-center my-3 text-red-800 justify-center">
                <i class="fas fa-exclamation-triangle text-3xl mr-6"></i>
                <p class="text-left">You don't have any private key stored in your browser.<br />Please <a @click="redirect('claims')" class="cursor-pointer underline font-bold">import or create</a> a valid one in order to use the sign function.</p>
            </div>
            <section v-show="mode=='text'">
                <h1 class="text-left text-xl mb-2">Clear Text</h1>
                <textarea class="w-full bg-gray-50 h-44 outline-none p-3 rounded-lg shadow-inner resize-none align-bottom" v-model="clearMessage" placeholder="Write your message here"></textarea>
                <button class="mt-5 px-6 py-2 text-lg text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg to-green-300 from-green-400 hover:scale-110 transform" @click="process()" >Process <i class="fas fa-key ml-1"></i></button>
                <h1 class="text-left text-xl mb-2">Generated Text</h1>
                <textarea class="w-full bg-gray-50 h-44 outline-none p-3 rounded-lg shadow-inner resize-none align-bottom" v-model="encryptedMessage" placeholder="The encrypted message will appear here" readonly></textarea>
            </section>
            <section v-show="mode=='file'">
                <h1 class="text-left text-xl mb-2">Import File</h1>
                <FileDropper class="" :file.sync="file"/>
                <button class="mt-5 px-6 py-2 text-lg text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg to-green-300 from-green-400 hover:scale-110 transform" @click="process()" >Process <i class="fas fa-key ml-1"></i></button>
                <DownloadFile v-if="downloadableFile" :downloadableFile="downloadableFile" :checkbox="checkbox" :filename="file.name"/>
            </section>
        </div>
    </div>

</template>

<script>
import { mapState } from 'vuex';
import { Contract } from 'ethers';
import ONCHAINID  from '@onchain-id/solidity';
import * as openpgp from 'openpgp';

import { getPGPPublicKey, getKeyFromLocalStorage } from '@/modules/utils';
import Checkbox from '@/components/Checkbox.vue';
import PassphraseModal from '@/components/PassphraseModal.vue';
import FileDropper from '@/components/FileDropper.vue';
import Tabs from '@/components/Tabs.vue';
import DownloadFile from '@/components/DownloadFile.vue';
import router from '@/router';

export default {
    components: {
        Checkbox,
        PassphraseModal,
        FileDropper,
        Tabs,
        DownloadFile,
    },
    data() {
        return {
            mounted: false,
            clearMessage: "",
            encryptedMessage: "",
            receiverAddress: {
                input: "",
                real: null,
            },
            error: "None",
            receiverPublicKey: null,
            checkbox: {
                encrypt: true,
                sign: true,
            },
            passphrase: {
                modalState: false,
                content: null,
            },
            privateKeyArmored: {
                identityAddress: null,
                key: null,
            },
            decryptedPrivateKey: null,
            callback: null,

            mode: "text",
            file: File || Object,
            downloadableFile: null,
            privateKeyInLocalStorage: true,
        }
    },
    computed: {
        ...mapState('global', ['provider', 'ENSContract', 'accountAddress', 'identity', 'connected'])
    },

    methods: {
        async checkAddressValidity() {
            if(!this.receiverAddress.input) {
                throw new Error('Missing receiver address');
            }
            if(!(this.receiverAddress.input.length === 42 && this.receiverAddress.input.startsWith('0x'))) {
                const response = await this.ENSContract.getAddressFromName(this.receiverAddress.input);
                if(response === "0x0000000000000000000000000000000000000000") {
                    throw new Error("Please enter a valid name or identity address");
                }
                this.receiverAddress.real = response;
            }
            else {
                this.receiverAddress.real = this.receiverAddress.input;
            }
            const receiverIdentity = new Contract(
                this.receiverAddress.real,
                ONCHAINID.interfaces.IClaimIssuer.abi,
                this.provider
            );
            this.receiverPublicKey = await getPGPPublicKey(receiverIdentity);
        },

        async checkMessageValidity() {
            if(!this.clearMessage) {
                throw new Error("Empty message");
            }
            await openpgp.createMessage({ text: this.clearMessage });
        },

        checkFileValidity() {
            if(this.file.size == 0) {
                throw new Error("Missing file");
            }
        },

        async process() {
            try {
                this.error = "None";

                if (this.mode === 'text') {
                    await this.checkMessageValidity();
                } else {
                    this.checkFileValidity();
                }

                if(this.checkbox.encrypt && this.checkbox.sign) {
                    await this.checkAddressValidity();
                    if(this.privateKeyArmored.identityAddress !== this.identity.address) {
                        await this.getPrivateKeyArmored();
                    }
                    if (this.mode === 'text') {
                        this.callback = this.signAndEncryptMessage;
                    }
                    else {
                        this.callback = this.signAndEncryptFile;
                    }
                    await this.getPrivateKey();
                    await this.callback();
                }
                else if(this.checkbox.encrypt) {
                    await this.checkAddressValidity();
                    if (this.mode === 'text') {
                        await this.encryptMessage();
                    } else {
                        await this.encryptFile();
                    }
                }
                else if(this.checkbox.sign) {
                    if(this.privateKeyArmored.identityAddress !== this.identity.address) {
                        await this.getPrivateKeyArmored();
                    }
                    if (this.mode === 'text') {
                        this.callback = this.signMessage;
                    } else {
                        this.callback = this.signFile;
                    }
                    await this.getPrivateKey();
                    await this.callback();
                }
                else {
                    if(this.mode === 'text') {
                        this.encryptedMessage = this.clearMessage;
                    }
                    else {
                        throw new Error('Please select Encrypt or/and Sign');
                    }
                }
            }
            catch(error) {
                if(error.message === 'Waiting for passphrase') return;
                if(error.code === 'INVALID_ARGUMENT') this.error = 'Address invalid';
                else if (error.code === 'UNSUPPORTED_OPERATION') this.error = 'Not an address';
                else {
                    this.error = error.message;
                }
            }
        },

        async decryptPGPKey(encryptedMessage) {
            const decryptedMessage = await window.ethereum.request({
                method: 'eth_decrypt',
                params: [encryptedMessage, this.accountAddress],
            })
            return decryptedMessage;
        },

        async getPrivateKeyArmored() {
            const encryptedKey = getKeyFromLocalStorage(this.identity.address);
            this.privateKeyArmored.key = await this.decryptPGPKey(encryptedKey);
            this.privateKeyArmored.identityAddress = this.identity.address;
        },

        async getPrivateKey() {
            let privateKey = await openpgp.readKey({ armoredKey: this.privateKeyArmored.key });
            if (privateKey.keyPacket.isEncrypted && this.passphrase.content === null) {
                    this.passphrase.modalState = true;
                    this.decryptedPrivateKey = null;
                    throw new Error('Waiting for passphrase');
            }
            else if (privateKey.keyPacket.isEncrypted) {
                try {
                    privateKey = await openpgp.decryptKey({
                        privateKey: await openpgp.readKey({ armoredKey: this.privateKeyArmored.key }),
                        passphrase: this.passphrase.content
                    });
                } catch(error) {
                    this.error = error.message;
                    this.passphrase.content = null;
                }
            }
            this.decryptedPrivateKey = privateKey;
        },

        async encryptMessage() {
            const publicKey = await openpgp.readKey({ armoredKey: this.receiverPublicKey });
            const encrypted = await openpgp.encrypt({
                message: await openpgp.createMessage({ text: this.clearMessage }),
                encryptionKeys: publicKey,
            });
            this.encryptedMessage = encrypted;
        },

        async signMessage() {
            const unsignedMessage = await openpgp.createCleartextMessage({ text: this.clearMessage });
            const cleartextMessage = await openpgp.sign({
                message: unsignedMessage,
                signingKeys: this.decryptedPrivateKey
            });
            this.encryptedMessage = cleartextMessage;
        },

        async signAndEncryptMessage() {
            const publicKey = await openpgp.readKey({ armoredKey: this.receiverPublicKey });

            const encrypted = await openpgp.encrypt({
                message: await openpgp.createMessage({ text: this.clearMessage }),
                encryptionKeys: publicKey,
                signingKeys: this.decryptedPrivateKey
            });

            this.encryptedMessage = encrypted;
        },

        async encryptFile() {
            const publicKey = await openpgp.readKey({ armoredKey: this.receiverPublicKey });
            const buffer = await this.file.arrayBuffer();
            const encrypted = await openpgp.encrypt({
                message: await openpgp.createMessage({ binary: new Uint8Array(buffer) }),
                encryptionKeys: publicKey,
                format: 'armored',
            });
            this.downloadableFile = encrypted;
        },

        async signFile() {
            const buffer = await this.file.arrayBuffer();
            const signedFile = await openpgp.sign({
                message: await openpgp.createMessage({ binary: new Uint8Array(buffer) }),
                signingKeys: this.decryptedPrivateKey,
                detached: true
            });
            if (!("TextEncoder" in window))
                alert("Sorry, this browser does not support TextEncoder...");
            var enc = new TextEncoder(); // always utf-8
            this.downloadableFile = enc.encode(signedFile);
        },

        async signAndEncryptFile() {
            const publicKey = await openpgp.readKey({ armoredKey: this.receiverPublicKey });
            const buffer = await this.file.arrayBuffer();
            const finalFile = await openpgp.encrypt({
                message: await openpgp.createMessage({ binary: new Uint8Array(buffer) }),
                encryptionKeys: publicKey,
                signingKeys: this.decryptedPrivateKey,
            });
            this.downloadableFile = finalFile;
        },

        async closePassphraseModal(pass) {
            this.passphrase.modalState = false;
            try {
                if(pass === null) {
                    this.error = "Passphrase incorrect";
                    return;
                }
                else {
                    this.passphrase.content = pass;
                    await this.getPrivateKey();
                    await this.callback();
                }
            } catch(error) {
                this.error = "Passphrase incorrect";
            }
        },

        redirect(option) {
            router.push(option).catch(() => {});
        }
    },

    mounted() {
        this.file = new File([""], "");
        try {
            getKeyFromLocalStorage(this.identity.address);
        } catch(error) {
            this.privateKeyInLocalStorage = false;
        }
        if(!this.identity || !this.privateKeyInLocalStorage) {
            this.checkbox.sign = false;
        }
        this.mounted = true;
    }
}
</script>
