<template>
	<div v-if="mounted" id="app">
		<link rel="stylesheet" 
        href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" 
        integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ" 
        crossorigin="anonymous">
		<button v-if="!connected" class="fixed top-12 right-16 px-6 py-2 text-lg text-white font-bold bg-gradient-to-b rounded-lg shadow-lg to-gray-800 from-gray-600 transition-transform transform hover:scale-110 focus:outline-none" @click="login">Login</button>
		<button v-else class="fixed top-12 right-16 px-6 py-2 text-lg text-white font-bold bg-gradient-to-b rounded-lg shadow-lg to-gray-800 from-gray-600 transition-transform transform hover:scale-110 focus:outline-none" @click="logout">Logout</button>
		<div class="z-10 mb-12 ">
			<router-link to="/" class="mx-3">Home</router-link>  
			<router-link to="/claims" class="mx-3">Keys Manager</router-link>  
			<router-link to="/encrypt" class="mx-3">Send</router-link>
			<router-link to="/decrypt" class="mx-3">Receive</router-link>
		</div>
		<router-view class="w-2/3 mx-auto"/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ethers } from 'ethers'
import { Identity } from "@onchain-id/identity-sdk";
import config from '@/config';
import { resolveAddressFromLocalStorage, removeAddressFromLocalStorage } from '@/modules/utils';
import router from '@/router';

export default {
	name: 'App',
	data() {
        return {
            mounted: false,
        }
    },
	computed: {
		...mapState('global', ['provider', 'signer', 'accountAddress', 'identity', 'connected'])
	},
	methods: {
		...mapActions('global', ['setAccountAddress', 'setProvider', 'setSigner', 'setENSContract', 'setIdentity', 'setConnected']),

		login() {
			router.push('/claims').catch(() => {});
		},

		async logout() {
			await removeAddressFromLocalStorage(this.accountAddress);
			this.setIdentity(null);
			this.setConnected(false);
			await window.location.reload();
		},

		async setNetwork() {
			await window.ethereum.request({
				method: 'wallet_addEthereumChain',
				params: [config['network']],
			})
			.then(() => {
				console.log('Connected to the correct network');
			})
			.catch(console.error);
		}
	},

	async mounted() {
		await this.setNetwork();
		const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        this.setAccountAddress(accounts[0]);
        this.setProvider(new ethers.providers.Web3Provider(window.ethereum));
        this.setSigner(this.provider.getSigner());
		const abi = [
			"function getNameFromAddress(address addr) external view returns(string memory name)",
			"function getAddressFromName(string calldata name) external view returns(address addr)",
		]
		const chainId = await window.ethereum.request({ method: 'eth_chainId' });
		try {
			const ensContractAddress = config["ENS_CONTRACT"][chainId];
			this.setENSContract(new ethers.Contract(ensContractAddress, abi, this.signer));
		} catch(error) {
			console.info("No ENS available");
		}
		const identityAddress = await resolveAddressFromLocalStorage(this.accountAddress);
		if(identityAddress) {
			const identity = await Identity.at(identityAddress, this.signer);
			this.setIdentity(identity);
			this.setConnected(true);
		}
		await window.ethereum.on('accountsChanged', async () => {
			await window.location.reload();
		});
		await window.ethereum.on('chainChanged', () => {
			window.location.reload()
		})
		this.mounted = true;
	}
}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin-top: 60px;
	}
</style>
