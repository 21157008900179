<template>
    <div class="">
        <div class="max-w-xl mx-auto my-10 p-12 rounded-xl shadow-xl">
            <h1 class="mb-5 text-4xl font-semibold">Connect</h1>
            <p class="text-gray-400 dark:text-gray-400 text-left mb-5">
              Identify with your identity address to access to your
              decentralized identity
            </p>
            <label htmlFor="address_id" class="block mb-2 text-xl">
                Identity address
            </label>
            <input
                type="text"
                v-model="address"
                placeholder="Enter your identity adress or your alias here"
                class="w-full text-center px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md 
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 
                                dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 
                                dark:focus:border-gray-500 mb-5"
            />
            <button @click="submit" class="w-full px-3 py-3 text-white text-lg font-bold transition-colors bg-green-400 rounded-md hover:bg-green-500 focus:outline-none">
            Login
            </button>
        </div>
        <div v-if="error" class="bg-red-300 border-l-4 border-red-700 text-red-600 max-w-xl mx-auto my-10 p-3 text-left leading-4">
            <p class="font-bold">Whoops !</p>
            <p>{{this.error}}</p>
        </div>
    </div>
</template>

<script>
import { Identity, IdentitySDK } from "@onchain-id/identity-sdk";
import { mapActions, mapState } from 'vuex';
export default {
    data() {
        return {
            address: null,
            error: null
        }
    },

    computed: {
        ...mapState('global', ['provider', 'signer', 'ENSContract'])
    },

    methods: {
        ...mapActions('global', ['setIdentity']),

        async loadIdentity() {
            const identity = await Identity.at(this.address, this.signer);
            const keys = await identity.getKeysByPurpose(
                IdentitySDK.utils.enums.KeyPurpose.MANAGEMENT
            );
            const hashedAddress = IdentitySDK.utils.encodeAndHash(["address"], [await this.signer.getAddress()]);
            for (const key of keys) {
                if (key.key === hashedAddress) {
                    return identity;
                }
            }
        },

        async submit() {
            if (!window.ethereum) {
                window.alert("Please install MetaMask first.");
                return;
            }

            if (!this.address) {
                this.error = "Please enter an identity address";
                return;
            }

            let identity = null;

            try {
                if (!this.address.startsWith("0x") || this.address.length !== 42) { 
                    const response = await this.ENSContract.getAddressFromName(this.address);
                    if (response === "0x0000000000000000000000000000000000000000") {
                        this.error = "Please enter a valid name or identity address";
                        return;
                    }
                    this.address = response;
                }
                identity = await this.loadIdentity();
            } catch (error) {
                console.error(error);
                this.error = "No identity is known with this address";
                return;
            }

            if (!identity) {
                this.error = "No identity is known with this address";
                return;
            }

            const walletPublicKey = this.signer.getAddress();

            const hashedUser = IdentitySDK.utils.encodeAndHash(["address"], [await walletPublicKey]);
            const authorized = await identity.keyHolderInstance.keyHasPurpose(hashedUser, IdentitySDK.utils.enums.KeyPurpose.MANAGEMENT);
            if (!authorized) {
                this.error = "You don't have the management key for this identity";
                this.address = null;
                return;
            }
            this.setIdentity(identity);
        }
    },
     watch: {
         error: {
             handler(newValue) {
                 if(newValue) {
                     setTimeout(() => {
                         this.error = null;
                     }, 5000);
                 }
             },
             immediate: false,
         }
     }
}
</script>