<template>
    <section>
        <div class="flex w-full max-h-full justify-center">
            <div class="flex loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-14 w-14 sm:h-24 sm:w-24"></div>
        </div>
        <p v-html="sentence" class="text-center text-greatBlue mt-7 mx-4 text-2xl"></p>
    </section>
</template>

<script>
export default {
    props: {
        sentence: String,
    },
}
</script>

<style scoped>
.loader {
  border-top-color: #34D399;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>