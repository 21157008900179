<template>
    <div v-if="mounted" class="mt-7">
        <div v-if="modal.createKey || modal.export || modal.import" class="fixed w-full z-20 top-0 bottom-0 left-0 overflow-y-auto">
            <div class="fixed w-full" aria-hidden="true">
                <div class="w-full h-screen bg-gray-500 opacity-75"></div>
            </div>
            <CreateKeys v-if="modal.createKey" @close="closeModal()"/>
            <ExportKey v-if="modal.export" @close="closeExportModal()"/>
            <ImportKey v-if="modal.import" @close="closeImportModal()"/>
        </div>
        <h1 class="text-4xl mb-7">Keys Manager</h1>
        <div class="mb-12">
            <div class="shadow-xl rounded-lg py-6 px-12 w-2/3 mx-auto mb-12 bg-white">
                <p class="text-center text-2xl">Identity Information</p>
                <p class="text-xl font-bold mt-4">Wallet</p>
                <p>{{ accountAddress }}</p>
                <p class="text-xl font-bold mt-4">Address</p>
                <p>{{ identity.address || "..." }}</p>
                <div class="flex items-center justify-center text-xl font-bold mt-4">
                    <p>Metamask Public Key</p>
                    <i class="fas text-xl ml-2 hover:scale-110 transition-transform transform cursor-pointer" :class="publicKey == '*'.repeat(44) ? 'fa-eye-slash' : 'fa-eye'" @click='getPublicKey()'></i>
                </div>
                <form class="flex flex-col w-full items-center justify-center">
                    <input :value="publicKey" class="text-center mx-3 w-full outline-none" :type="publicKey == '*'.repeat(44) ? 'password' : 'text'" readonly autocomplete="off">
                </form>
                <div class="flex flex-col items-center justify-center mt-4 text-lg">
                    <p class="text-xl font-bold mr-3">PGP Keypair</p>
                    <!-- <button class="w-52 mt-3 px-6 py-2 text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg to-green-300 from-green-400 hover:scale-110 transform">Import Keypair</button>  -->
                    <div v-if="PGPassertion" class="flex flex-col items-center justify-center">
                        <div class="flex items-center justify-center">
                            <p class="py-1 text-xl text-green-400 font-bold"><i class="fas fa-check-circle mr-2"></i>Yes</p>
                            <i class="fas fa-sync-alt ml-4 text-lg rotate mb-1 z-10 cursor-pointer transform transition-all hover:rotate-180 duration-500" @mouseover="hover=true" @mouseleave="hover=false" @click="openModal()"></i>
                            <p class="absolute transition-all duration-500 transform" :class="!hover ? 'opacity-0 translate-x-1/2' : 'translate-x-full' ">New PGP keys</p>
                        </div>
                        <button class="w-52 mt-3 px-6 py-2 text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg to-green-300 from-green-400 hover:scale-110" :class="privateKeyAssertion ? 'transform' : 'cursor-default opacity-50'" @click="openExportModal()" :disabled="!privateKeyAssertion">Export</button> 
                        <button class="w-52 mt-3 px-6 py-2  text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg to-gray-600 from-gray-700 hover:scale-110 transform" @click="openImportModal()">Import</button>
                        <div v-if="note" class="flex w-full bg-gray-300 rounded-sm border-l-4 border-gray-700 text-gray-600 mx-auto mt-4 p-2 text-left transition-opacity text-base" :class="note != 'None' ? 'opacity-100' : 'opacity-0'">
                            <p class="font-bold mx-2">Note:</p>
                            <p class="mr-2">{{this.note}}</p>
                        </div> 
                    </div>
                    <button v-else class="w-52 mt-3 px-6 py-2 text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg to-gray-500 from-gray-600 hover:scale-110 transform" @click="openModal()">New PGP Keys</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CreateKeys from '@/components/CreateKeys.vue';
import ExportKey from "@/components/ExportKey.vue";
import ImportKey from "@/components/ImportKey.vue";

export default {
    components: {
        CreateKeys,
        ExportKey,
        ImportKey
    },
    data() {
        return {
            mounted: false,
            PGPassertion: null,
            hover: false,
            modal: {
                createKey: false,
                export: false,
                import: false,
            },
            note: 'None',
            publicKey: '*'.repeat(44),
            privateKey: null,
        }
    },
    computed: {
        ...mapState('global', ['identity', 'signer', 'provider', 'accountAddress']),

    },
    methods: {
        ...mapActions('global', ['setIdentity']),

        async checkPGPClaim() {
            const PGPclaims = await this.identity.getClaimIdsByTopic(4880);
            if(PGPclaims.length > 0) {
                this.PGPassertion = true;
            }
            else {
                this.PGPassertion = false;
            }
        },

        checkPrivateKey() {
            this.note = 'None';
            const keysString = window.localStorage.getItem('privatePGPKey');
            try {
                if(keysString == null) {
                    throw new Error('missing private key')
                }
                const keysList = JSON.parse(keysString);
                const encryptedKey = keysList[this.identity.address];
                if(encryptedKey == null) {
                    throw new Error('missing private key')
                }
                else {
                    this.privateKeyAssertion = true;
                }
            } catch(error) {
                this.privateKeyAssertion = false;
                this.note = "Private key missing. Please import it from another session"
            }
        },

        openModal() {
            this.modal.createKey = true;
        },

        openExportModal() {
            this.modal.export = true;
        },

        openImportModal() {
            this.modal.import = true;
        },

        closeModal() {
            this.checkPGPClaim();
            this.modal.createKey = false;
        },

        closeExportModal() {
            this.modal.export = false;
        },

        async closeImportModal() {
            await this.checkPGPClaim();
            this.checkPrivateKey();
            this.modal.import = false;
        },

        async getPublicKey() {
            if(this.publicKey == '*'.repeat(44)) {
                this.publicKey = await window.ethereum.request({
                    method: 'eth_getEncryptionPublicKey',
                    params: [this.accountAddress], // you must have access to the specified account
                });
            }
            else {
                this.publicKey = '*'.repeat(44);
            }
        }
    },

    async mounted() {
        if(this.identity) {
            await this.checkPGPClaim();
            this.checkPrivateKey();
        }
        this.mounted = true;
    }
}
</script>