<template>
    <div class="grid grid-cols-2">
            <div class="rounded-tl-lg transition-all cursor-pointer select-none" @click="$emit('update:mode', 'text')" :class="mode == 'text' ? 'bg-green-300 shadow-inner text-green-700' : 'bg-gradient-to-br from-white to-gray-100 shadow-lg text-gray-400'">
                <p class="my-3 text-xl font-medium">Text</p>
            </div>
            <div class="bg-gradient-to-br rounded-tr-lg transition-all cursor-pointer select-none" @click="$emit('update:mode', 'file')" :class="mode == 'file' ? 'bg-green-300 shadow-inner text-green-700' : 'bg-gradient-to-br from-white to-gray-100 shadow-lg text-gray-400'">
                <p class="my-3 text-xl font-medium">File</p>
            </div>
    </div>
</template>

<script>

export default {
    props: {
        mode: String
    },
    data() {
        return {
            
        }
    },
}
</script>
