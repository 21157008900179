<template>
    <div class="">
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-2xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <i v-if="phase != 'processing'" class="absolute text-xl transition-transform transform cursor-pointer hover:scale-110 top-6 right-6 fas fa-times" @click="close()"></i>
                <div class="flex flex-col mt-3 text-center sm:mt-0 sm:ml-4 ">
                    <h3 class="text-2xl font-medium text-gray-900">New PGP Keys</h3>
                    <div v-if="phase == 'form'" class="mt-5 w-5/6 mx-auto">
                        <p class="text-left mb-5">
                            You can choose the encryption type for your PGP key pair and provide a passphrase for the private key (leave blank if you don't want one).
                        </p>
                        <form class="text-right font-bold grid grid-cols-3 gap-4 items-center mb-2">
                            <p>Encryption type</p>
                            <select v-model="selectedEncryption" class="col-span-2 shadow border rounded w-1/2 py-2 px-3 outline-none">
                                <option v-for="option in this.encryptionTypes" v-bind:value="option.type" v-bind:key="option.value">
                                    {{ option.text }}
                                </option>
                            </select>
                            <p>Passphrase</p>
                            <input class="col-span-2 shadow appearance-none border rounded py-2 px-3 outline-none" v-model="passphrase" type="password" autocomplete="off" placeholder="Enter additional data here">
                            <p>Confirm passphrase</p>
                            <input class="col-span-2 shadow appearance-none border rounded py-2 px-3 outline-none" v-model="passphraseConfirmation" type="password" autocomplete="off" placeholder="Enter additional data here">
                        </form>
                        <div class="text-center text-red-500 text-md font-medium transition-opacity duration-400 mb-1" :class="error != 'None' ? 'opacity-100' : 'opacity-0'">{{this.error}}</div>
                        <button class="mb-2 px-6 py-2 text-lg text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg to-green-300 from-green-400 hover:scale-110 transform" @click="submit()">Submit</button> 
                    </div>
                    <Stepper v-else-if="phase == 'processing'" class="mt-5 w-5/6 mx-auto text-center" :stepper="stepper" />
                    <div v-else-if="phase == 'success'" class="mt-7 w-5/6 mx-auto">
                        <i class="fas fa-check-circle bg-gradient-to-br text-green-400" style="font-size: 7rem"></i>
                        <p class="my-3 text-center">PGP keypair successfully created</p>
                        <button class="my-5 px-6 py-2 text-lg text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg from-green-400 to-green-300 hover:scale-110 transform" @click="close()">Close</button> 
                    </div>
                    <div v-else-if="phase == 'failed'" class="mt-7 w-5/6 mx-auto">
                        <i class="fas fa-times-circle bg-gradient-to-br text-red-400" style="font-size: 7rem"></i>
                        <p class="my-3 text-center">{{ error }}</p>
                        <button class="my-5 px-6 py-2 text-lg text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg from-red-400 to-red-300 hover:scale-110 transform" @click="close()">Close</button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import * as openpgp from 'openpgp';
import * as ethUtil from 'ethereumjs-util';
import * as sigUtil from 'eth-sig-util';
import { createClaim } from '@/modules/claims';
import Stepper from '@/components/Stepper.vue';
import { savePrivateKeyToLocalStorage } from '@/modules/utils';
import config from '@/config';
export default {
    components: {
        Stepper
    },
    data() {
        return {
            selectedEncryption: "rsa",
            encryptionTypes: [
                {
                    text: "RSA",
                    type: "rsa"
                },
            ],
            passphrase: '',
            passphraseConfirmation: '',
            error: 'None',
            phase: 'form',
            stepper: {
                counter: 0,
                steps: [
                    'Need your Metamask Public Key in order to encrypt the PGP private key',
                    'Need you to sign the claim containing your public key',
                    'Need you to confirm the transaction to emit the claim on the blockchain',
                    'Waiting the transaction to complete...'
                ]
            },
            hyperlinkElements: ['Waiting the transaction to complete...<br/>You can see the transaction details <a href="', '" class="text-blue-500 hover:underline visited:text-pink-600" target="_blank">here</a>']
        }
    },

    computed: {
        ...mapState('global', ['accountAddress', 'identity', 'signer'])
    },

    methods: {
        passphraseValidation() {
            if(this.passphrase !== this.passphraseConfirmation) {
                throw new Error("Confirmation passphrase different");
            }
        },

        async submit() {
            try {
                // verify password
                this.passphraseValidation();
                this.error = 'None'
                // generate the GPG keys
                this.phase = 'processing';
                this.stepper.counter = 0;
                const keys = await this.generatePGPKey();
                // store the private key in localStorage
                savePrivateKeyToLocalStorage(this.identity.address, keys.private)
                // store the public key as a claim on the blockchain
                await this.submitClaim(keys.public);
                this.phase = 'success';
            } catch(error) {
                if(error.message === 'Stopped by the user') {
                    this.phase = 'failed';
                }
                this.error = error.message;
            }
        },

        generateOptionsForKey() {
            let options = {};
            if(this.selectedEncryption === 'rsa') {
                options['type'] = 'rsa';
                options['rsaBits'] = 4096;
            }
            if(this.passphrase.length > 0) {
                options['passphrase'] = this.passphrase;
            }
            options['userIDs'] = [{ address: this.accountAddress }];
            return options;
        },

        async generatePGPKey() {
            const options = this.generateOptionsForKey();
            const { privateKeyArmored, publicKeyArmored } = await openpgp.generateKey(options);

            // retrieve the publicKey from MetaMask for encryption
            let encryptionPublicKey;
            try {
                encryptionPublicKey = await this.getPublicEncryptionKey();
                this.stepper.counter++;
            } catch(error) {
                if (error.code === 4001) {
                    // EIP-1193 userRejectedRequest error
                    console.log("We can't encrypt anything without the key.");
                } else {
                    throw new Error('Stopped by the user');
                }
            }
            // encrypt the public GPG Key
            const encryptedMessage = ethUtil.bufferToHex(
            Buffer.from(
                JSON.stringify(
                    sigUtil.encrypt(
                        encryptionPublicKey,
                        { data: privateKeyArmored },
                        'x25519-xsalsa20-poly1305'
                    )
                ),
                'utf8'
            ));
            return { private: encryptedMessage, public: publicKeyArmored };
        },

        async submitClaim(data) {
            try {
                const claim = await createClaim(data, this.identity, this.signer);
                this.stepper.counter++;
                const tx = await this.identity.addClaim(claim.topic, claim.scheme, claim.issuer, claim.signature, claim.data, claim.uri, {signer: this.signer});
                if(config["network"]["blockExplorerUrls"] && config["network"]["blockExplorerUrls"].length > 0) {
                    this.stepper.steps[3] = this.hyperlinkElements[0] + config['network']["blockExplorerUrls"][0] + '/tx/' + tx.hash + this.hyperlinkElements[1]
                }
                this.stepper.counter++;
                await tx.wait();
            } catch(error) {
                this.phase = 'failed';
                this.error = error.message;
                console.error("Error while processing the transaction", error);
                throw new Error(error.message);
            }
        },

        async getPublicEncryptionKey() {
            try {
                const encryptionPublicKey = await window.ethereum.request({
                    method: 'eth_getEncryptionPublicKey',
                    params: [this.accountAddress], // you must have access to the specified account
                })
                return encryptionPublicKey;
            } catch (error) {
                throw new Error(error);
            }
        },

        close() {
            this.$emit('close');
        }
    }
}

</script>
