<template>
    <div>
        <button class="bg-yellow-500 w-full py-2 border-solid border border-black" @click="login">
        Connect with MetaMask
        </button>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { IdentitySDK } from "@onchain-id/identity-sdk";
import config from '@/config';

export default {
    props: {
        onSuccess: Function,
        onFailure: Function
    },

    data() {
        return {

        }       
    },

    computed: {
        ...mapState('global', ['signer', 'identity']),
    },

    methods: {
        async login() {
            try {
                const publicKeyMatching = await this.signChallenge();
                const authorized = await this.isConnectionApproved();
                if (publicKeyMatching && authorized) {
                    this.onSuccess();
                }
                else {
                    this.onFailure("Authentication failed");
                }
            } catch(error) {
                this.onFailure(error);
            }
        },

        async signChallenge() {
            const walletPublicKey = await this.signer.getAddress();        
            const challenge = await fetch(
            config.SERVER + "/challenge/create",
            {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    address: walletPublicKey,
                }),
            }).then(response => response.json());
            let message = `I want to log into this website (${challenge.challenge})`;
            try {
                const signature = await this.signer.signMessage(message);
                const responseData = await fetch(
                    config.SERVER + "/challenge/verify",
                    {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        address: walletPublicKey,
                        challenge: signature
                    }),
                    }
                ).then(response => response.json());
                return responseData.isValid;
            } catch(error) {
                throw new Error("User stopped the process");
            }
        },

        async isConnectionApproved() {
            const hashedUser = IdentitySDK.utils.encodeAndHash(["address"], [await this.signer.getAddress()]);
            return await this.identity.keyHolderInstance.keyHasPurpose(hashedUser, IdentitySDK.utils.enums.KeyPurpose.MANAGEMENT);
        }
    },
}
</script>
