import { ethers } from "ethers";

function hex2ascii(str1) {
    const hex = str1.toString();
    let str = "";
    let init = 0;

    if (str1.substring(0, 2) === "0x") {
        init = 2;
    }

    for (let n = init; n < hex.length; n += 2) {
       str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
}

async function getPGPPublicKey(identity) {
    const claimsId = await identity.getClaimIdsByTopic(4880);
    if(claimsId.length === 0) {
        throw new Error("Identity is missing a PGP claim");
    }
    const claim = await identity.getClaim(claimsId[0]);
    const asciiData = hex2ascii(claim.data);
    return asciiData;
}

function savePrivateKeyToLocalStorage(identityAddress, privateKey) {
    const keysString = window.localStorage.getItem('privatePGPKey');
    let keysList = {};
    if(keysString !== null) {
        keysList = JSON.parse(keysString)
    }
    keysList[identityAddress] = privateKey;
    window.localStorage.setItem('privatePGPKey', JSON.stringify(keysList));
}

function getKeyFromLocalStorage(identityAddress) {
    const keysString = window.localStorage.getItem('privatePGPKey');
    if(keysString == null) throw new Error('Private key not found in your browser');
    const keysList = JSON.parse(keysString);
    const encryptedKey = keysList[identityAddress];
    if(encryptedKey == null) throw new Error('Private key not found in your browser');
    return encryptedKey;  
}

function hashStrInSha256(googleId) {
    const hashId = ethers.utils.sha256(ethers.utils.toUtf8Bytes(googleId));
    return hashId;
}

async function resolveAddressFromLocalStorage(accountAddress) {
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    const listString = window.localStorage.getItem('addressResolver');
    if(listString == null) return null;
    const list = JSON.parse(listString);
    if(list == {}) return null;
    try {
        const idAddress = list[chainId][accountAddress];
        if(idAddress) return idAddress;
        return null;
    } catch(error) {
        return null;
    }
}

async function setAddressInLocalStorage(accountAddress, identityAddress) {
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    const listString = window.localStorage.getItem('addressResolver');
    let list = {};
    if(listString != null) {
        list = JSON.parse(listString);
    }
    if(!list[chainId]) list[chainId] = {};
    list[chainId][accountAddress] = identityAddress;
    window.localStorage.setItem('addressResolver', JSON.stringify(list));
}

async function removeAddressFromLocalStorage(accountAddress) {
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    const listString = window.localStorage.getItem('addressResolver');
    let list = {};
    if(listString != null) {
        list = JSON.parse(listString);
    }
    if(!list[chainId]) return;
    delete list[chainId][accountAddress];
    window.localStorage.setItem('addressResolver', JSON.stringify(list));
}



export {
    hex2ascii,
    getPGPPublicKey,
    savePrivateKeyToLocalStorage,
    hashStrInSha256,
    getKeyFromLocalStorage,
    resolveAddressFromLocalStorage,
    setAddressInLocalStorage,
    removeAddressFromLocalStorage
}