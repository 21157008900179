<template>
    <div class="mt-5 w-5/6 mx-auto text-center">
        <Spinner />
        <p v-html="stepper.steps[stepper.counter]" class="text-lg my-5"></p>
        <p class="font-bold">{{`${stepper.counter + 1}/${stepper.steps.length}`}}</p>
    </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';

export default {
    
    components: {
        Spinner
    },

    props: {
        stepper: Object,
    },

    data() {
        return {

        }
    },
}
</script>
