<template>
    <div class="">
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-2xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <i class="absolute text-xl transition-transform transform cursor-pointer hover:scale-110 top-6 right-6 fas fa-times" @click="close()"></i>
                <div class="flex flex-col mt-3 text-center sm:mt-0">
                    <h3 class="text-2xl font-medium text-gray-900">Export Private Key</h3>
                    <div v-if="phase == 'form'" class="mt-5 w-5/6 mx-auto">
                        <p class="mb-3 text-left">We will export your PGP private key to a new account. We first need the public key of the new account.</p>
                        <div class="">
                            <h1 class="text-left text-xl mb-2">Metamask Public Key</h1>
                            <input class="w-full bg-gray-50 outline-none p-3 text-center rounded-lg shadow-inner resize-none" v-model="publicKey" placeholder="Enter your public key here">
                        </div>
                        <div class="text-center text-red-500 text-md font-medium my-2" :class="error != 'None' ? 'opacity-100' : 'opacity-0'">{{this.error}}</div>
                        <button class="mb-5 px-6 py-2 text-lg text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg to-green-300 from-green-400 hover:scale-110 transform" @click="exportKey()">Export</button> 
                    </div>
                    <Stepper v-else-if="phase == 'processing'" class="mt-5 w-5/6 mx-auto text-center" :stepper="stepper" />
                    <div v-else-if="phase == 'failed'" class="mt-7 w-5/6 mx-auto">
                        <i class="fas fa-times-circle bg-gradient-to-br text-red-400" style="font-size: 7rem"></i>
                        <p class="my-3 text-center">{{ error }}</p>
                        <button class="my-5 px-6 py-2 text-lg text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg from-red-400 to-red-300 hover:scale-110 transform" @click="close()">Close</button> 
                    </div>
                    <div v-else class="mt-5 w-5/6 mx-auto">
                        <p class="mb-3 text-left">The private key has been encrypted with the provided public key. You can now copy it.</p>
                        <div class="flex items-center mb-2">
                            <h1 class="text-left text-xl">Encrypted Private Key</h1>
                            <i class="fas fa-copy text-xl ml-3 cursor-pointer text-gray-500 transform transition-transform hover:scale-110" title="Copy" @click="textToClipboard()"></i>
                        </div>
                        <textarea ref="text" class="w-full bg-gray-50 h-36 outline-none p-4 rounded-lg shadow-inner resize-none" :value="finalMessage" readonly></textarea>
                        <div class="text-center text-md font-medium my-1" :class="error != 'None' ? 'opacity-100' : 'opacity-0'">{{this.error}}</div>
                        <button class="mb-5 mt-1 px-6 py-2 text-lg text-white font-bold bg-gradient-to-br rounded-lg transition-transform focus:outline-none shadow-lg to-green-300 from-green-400 hover:scale-110 transform" @click="close()">Close</button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import * as ethUtil from 'ethereumjs-util';
import * as sigUtil from 'eth-sig-util';
import Stepper from '@/components/Stepper.vue';
export default {
    components: {
        Stepper,
    },
    data() {
        return {
            error: 'None',
            publicKey: null,
            finalMessage: null,
            phase: 'form',
            stepper: {
                counter: 0,
                steps: [
                    'Need your Metamask Private Key in order to decrypt the PGP private key',
                ]
            }
        }
    },

    computed: {
        ...mapState('global', ['accountAddress', 'identity', 'signer'])
    },

    methods: {
        async exportKey() {
            const keysString = window.localStorage.getItem('privatePGPKey');
            const keysList = JSON.parse(keysString);
            const encryptedKey = keysList[this.identity.address];
            let decryptedMessage;
            try {
                this.phase = "processing";
                decryptedMessage = await window.ethereum.request({ method: 'eth_decrypt', params: [encryptedKey, this.accountAddress] })
                const encryptedMessage = ethUtil.bufferToHex(
                Buffer.from(
                    JSON.stringify(
                    sigUtil.encrypt(
                        this.publicKey,
                        { data: decryptedMessage },
                        'x25519-xsalsa20-poly1305'
                    )),
                    'utf8'
                ));
                this.finalMessage = encryptedMessage;
                this.phase = "success";

            } catch(error) {
                this.phase = 'failed';
                this.error = error.message;
                console.log(error);
            }
        },

        textToClipboard() {
            this.$refs.text.select();
            document.execCommand("copy");
            this.error = "Private key copied to clipboard"
            setTimeout(() => {
                this.error = 'None'
            }, 3000);
        },

        close() {
            this.$emit('close');
        }
    }
}

</script>
