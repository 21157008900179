<template>
<div>
    <div v-if="mounted" class="text-center">
        <div class="m-auto max-w-2xl bg-white border-1 border-gray-300 p-6 tracking-wide shadow-lg rounded-xl">
            <h1 class="mt-3 mb-10 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                Authenticate with one of the methods available
            </h1>
            <h2 class="my-3 text-xl font-semibold text-gray-700 dark:text-gray-200">
                Authentification methods that you allowed
            </h2>
            <div v-if="this.userAddedMethod.length === 0" class="m-2">
                You didn't allowed any authentification method
            </div>
            <div class="mx-auto w-1/3 my-6">
                <GoogleButton class="mb-3" v-if="this.userAddedMethod.includes(config.TOPICS.GOOGLE)" :onSuccess="success" :onFailure="failure"/>
                <MicrosoftButton class="mb-3" v-if="this.userAddedMethod.includes(config.TOPICS.MICROSOFT)" :onSuccess="success" :onFailure="failure"/>
                <FIDOButton class="mb-3" v-if="this.userAddedMethod.includes(config.TOPICS.FIDO)" :onSuccess="success" :onFailure="failure"/>
                <MetaMaskButton class="mb-3" v-if="this.allowedMethods.includes(config.TOPICS.METAMASK)" :onSuccess="success" :onFailure="failure"/>
                <GitHubButton class="mb-3" v-if="this.allowedMethods.includes(config.TOPICS.GITHUB)" :onSuccess="success" :onFailure="failure" :address="identity.address"/>
            </div>
            
            <h2 class="my-3 text-xl font-semibold text-gray-700 dark:text-gray-200">
                Authentification methods that we allow
            </h2>
            <div>
                <div v-if="this.allowedMethods.length === 0" class="m-2">
                    We didn't allow any authentification method
                </div>
                <div v-if="this.allowedMethods.includes(config.TOPICS.GOOGLE)">Google Sign In</div>
                <div v-if="this.allowedMethods.includes(config.TOPICS.MICROSOFT)">Microsoft Sign In</div>
                <div v-if="this.allowedMethods.includes(config.TOPICS.FIDO)">FIDO2</div>
                <div v-if="this.allowedMethods.includes(config.TOPICS.METAMASK)">MetaMask</div>
            </div>
        </div>
        <div v-if="this.error" class="bg-red-300 border-l-4 border-red-700 text-red-600 max-w-xl mx-auto my-10 p-3 text-left leading-4">
            <p class="font-bold">Warning</p>
            <p>{{this.error}}</p>
        </div>
    </div>
    <div v-else>
        <Spinner sentence="Please wait... Retrieving information from the server" class="fixed transform top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 scale-125"/>
    </div>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import router from '@/router';
import config from "@/config.js";
import GoogleButton from '@/components/buttons/GoogleButton.vue';
import MicrosoftButton from '@/components/buttons/MicrosoftButton.vue';
import FIDOButton from '@/components/buttons/FIDOButton.vue';
import MetaMaskButton from '@/components/buttons/MetaMaskButton.vue';
import GitHubButton from '@/components/buttons/GitHubButton.vue';
import Spinner from '@/components/Spinner.vue';
import { setAddressInLocalStorage } from '@/modules/utils';

export default {
    components: {
        GoogleButton,
        MicrosoftButton,
        FIDOButton,
        MetaMaskButton,
        Spinner,
        GitHubButton
    },

    data() {
        return {
            allowedMethods: [],
            userAddedMethod: [],
            error: null,
            config,
            mounted: false,
        } 
    },

    computed: {
        ...mapState('global', ['identity', 'accountAddress']),
    },

    methods: {
        ...mapActions('global', ['setConnected']),

        async success() {
            await setAddressInLocalStorage(this.accountAddress, this.identity.address);
            this.setConnected(true);
            router.push(this.$route.query.redirect ?? '/').catch(() => {});
        },

        failure(message) {
            this.error = message
            setTimeout(() => {
                this.error = null
            }, 5000); 
        },

        async getAllowedMethods() {
            const methodsFromServer = await fetch(
                config.SERVER + "/auth/methods",
                {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                }
            ).then(response => response.json());

            this.allowedMethods = Object.values(methodsFromServer.methods);
        },

        getUserAllowedMethods() {
            this.allowedMethods.forEach(async (method) => {
                const claimsId = await this.identity.getClaimIdsByTopic(method);
                if (claimsId.length > 0) {
                    this.userAddedMethod.push(method);
                }
            });
        }
    },

    async mounted() {
        await this.getAllowedMethods();
        await this.getUserAllowedMethods();
        this.mounted = true;
    }
}
</script>
