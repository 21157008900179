<template>
    <div class="absolute h-screen w-full top-0 transform translate-y-1/2 zoverflow-hidden -mt-24">
        <Spinner v-if="status=='processing'" sentence="Verification, please wait..."/>
        <div v-else-if="status=='rejected'">
            <p class="text-2xl text-red-600">Connection failed. Please retry</p>
        </div>        
    </div>
</template>

<script>
import Spinner from '@/components/Spinner';
import config from '@/config';
import { mapActions, mapState } from 'vuex';
import { hashStrInSha256 } from '@/modules/utils';
import { getClaim } from '@/modules/claims';
import { Identity } from "@onchain-id/identity-sdk";
import router from '@/router';
import { setAddressInLocalStorage } from '@/modules/utils';

export default {
    components: {
        Spinner
    },

    data() {
        return {
            status: "processing"
        }
    },

    computed: {
        ...mapState('global', ['identity', 'signer', 'accountAddress']),
    },

    methods: {
        ...mapActions('global', ['setIdentity', 'setConnected']),

        async verification() {
            const access_token = await fetch(`${config["SERVER"]}/auth/github/${this.$route.query.code}`, {
                method: "GET",
            })
            .then(async (response) => (await response.json())["token"]);
            if(!access_token) {
                this.status = 'rejected';
            }
            const res = await fetch(`https://api.github.com/user`, {
                headers: {
                    Authorization: `token ${access_token}`,
                },
            })
            .then(async (response) => await response.json());
            const id = res.id;
            if (!id) {
                this.status = 'rejected';
                return;
            }
            const claim = await this.retieveClaim();
            const hashId = hashStrInSha256(id);
            if (claim && claim.data === hashId) {
                await this.success();
            } else {
                this.status = 'rejected';
            }

        },

        async retieveClaim() {
            const claim = await getClaim(
                this.identity,
                this.identity,
                config["TOPICS"]["GITHUB"]
            );
            return claim;
        },

        async success() {
            await setAddressInLocalStorage(this.accountAddress, this.identity.address);
            this.setConnected(true);
            router.push('/').catch(() => {});
        },
    },

    async mounted() {
        if(!this.$route.query.address)
            this.status = "rejected";
        else {
            const identity = await Identity.at(this.$route.query.address, this.signer);
            this.setIdentity(identity);
        }

        if(this.$route.query.code)
            await this.verification();
        
        else this.status = "rejected"
    }
}
</script>
